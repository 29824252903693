import React from 'react'

import '../ServePost.css'

import hono0 from '../../../assets/visual/bx_hono/hono_000.png'
import hono1 from '../../../assets/visual/bx_hono/hono_001.png'
import hono2 from '../../../assets/visual/bx_hono/hono_002.png'
import hono3 from '../../../assets/visual/bx_hono/hono_003.png'
import ChevronRightArrow from '../../../assets/ChevronArrow.svg'



function VisualPost5() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={hono0} alt="" />
            <img className='content-img' src={hono1} alt="" />
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/987983000?h=34ff89278d?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
            </div>
            <img className='content-img' src={hono2} alt="" />
            <img className='content-img' src={hono3} alt="" />
           
                       <br></br>
                       <br></br>
                       <img className='arrow' src={ChevronRightArrow} alt="" /><br></br>
                       <a href='https://honoreport.com/'>hono site 이동하기 </a><br></br>
        </div>
    )
}

export default VisualPost5