import React from 'react'

import '../ServePost.css'

import freedom0 from '../../../assets/fashion/pictorial_freedom/freedom_000.jpg'
import freedom1 from '../../../assets/fashion/pictorial_freedom/freedom_001.png'
import freedom2 from '../../../assets/fashion/pictorial_freedom/freedom_002.png'
import freedom3 from '../../../assets/fashion/pictorial_freedom/freedom_003.png'
import freedom4 from '../../../assets/fashion/pictorial_freedom/freedom_004.png'
import freedom5 from '../../../assets/fashion/pictorial_freedom/freedom_005.png'


function FashionPost2() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={freedom0} alt="" />
            <img className='content-img' src={freedom1} alt="" />
            <img className='content-img' src={freedom2} alt="" />
            <img className='content-img' src={freedom3} alt="" />
            <img className='content-img' src={freedom4} alt="" />
            <img className='content-img' src={freedom5} alt="" />
        </div>
    )
}




export default FashionPost2

