import React from 'react'

import '../ServePost.css'

import www0 from '../../../assets/visual/bx_www/www_000.png'
import www1 from '../../../assets/visual/bx_www/www_001.png'
import www2 from '../../../assets/visual/bx_www/www_002.png'
import www3 from '../../../assets/visual/bx_www/www_003.png'
import www4 from '../../../assets/visual/bx_www/www_004.png'
import www5 from '../../../assets/visual/bx_www/www_005.png'
import www6 from '../../../assets/visual/bx_www/www_006.png'
import www7 from '../../../assets/visual/bx_www/www_007.png'
import www8 from '../../../assets/visual/bx_www/www_008.png'
import www9 from '../../../assets/visual/bx_www/www_009.png'
import www10 from '../../../assets/visual/bx_www/www_010.png'
import www11 from '../../../assets/visual/bx_www/www_011.png'
import www12 from '../../../assets/visual/bx_www/www_012.png'
import ChevronRightArrow from '../../../assets/ChevronArrow.svg'



function VisualPost2() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={www0} alt="" />
            <div className='black-back'>
            <img className='content-img' src={www1} alt="" />
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865340902?h=9dd5448b0a?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
                </div>
            <img className='content-img' src={www2} alt="" />
            <img className='content-img' src={www3} alt="" />
            <img className='content-img' src={www4} alt="" />
            <div className='content-video' >
                <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865339780?h=ca6ab46494?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
            </div>
            <br></br>
            <br></br>
            <div className='content-video' >
                <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865339830?h=afeecf18c5?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
            </div>
            </div>
            <img className='content-img' src={www5} alt="" />
            <img className='content-img' src={www6} alt="" />
            <img className='content-img' src={www7} alt="" />
            <div className='black-back'>
            <img className='content-img' src={www8} alt="" />
            <div className='content-video' >
                <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865339894?h=200c30d666?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
            </div>
            <img className='content-img' src={www9} alt="" />
            <div className='content-video' >
                <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865342070?h=67f11cf8c3?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
            </div>
            <div className='content-video' >
                <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865339949?h=fc035496d4?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
            </div>
            <div className='content-video' >
                <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865339915?h=aa8d775270?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
            </div>
            </div>
            <img className='content-img' src={www10} alt="" />
            <img className='content-img' src={www11} alt="" />
            <img className='content-img' src={www12} alt="" />
            <br></br>
            <br></br>
            <img className='arrow' src={ChevronRightArrow} alt="" /><br></br>
            <a href='https://wwweb.kr'>WWW 사이트 보러가기 </a><br></br>
            <a href='https://www.instagram.com/www_exhibition/'>WWW 인스타그램 보러가기 </a>
        </div>
    )
}

export default VisualPost2