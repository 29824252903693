import React from 'react'

import '../ServePost.css'

import rest0 from '../../../assets/media/cg_takearest/takearest_000.png'
import rest1 from '../../../assets/media/cg_takearest/takearest_001.png'
import rest2 from '../../../assets/media/cg_takearest/takearest_002.png'
import rest3 from '../../../assets/media/cg_takearest/takearest_003.png'

function MediaPost8() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={rest0} alt="" />
            <img className='content-img' src={rest1} alt="" />
            <img className='content-img' src={rest2} alt="" />
            <img className='content-img' src={rest3} alt="" />
        </div>
    )
}

export default MediaPost8