import React from 'react'

import '../ServePost.css'

import noemoda0 from '../../../assets/fashion/textile_neomoda/neomoda_tex_000.png'
import noemoda1 from '../../../assets/fashion/textile_neomoda/neomoda_tex_001.png'
import noemoda2 from '../../../assets/fashion/textile_neomoda/neomoda_tex_002.png'

function FashionPost4() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={noemoda0} alt="" />
            <img className='content-img' src={noemoda1} alt="" />
            <img className='content-img' src={noemoda2} alt="" />
        </div>
    )
}


export default FashionPost4
