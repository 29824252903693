import React from 'react'

import '../ServePost.css'

import ozel1 from '../../../assets/media/uiux_ozel/ozel_001.png'

function MediaPost10() {

    return (
        <div className='serve-content-body'>
            
            <img className='content-img' src={ozel1} alt="" />
        </div>
    )
}

export default MediaPost10