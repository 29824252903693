import React from 'react'

import '../ServePost.css'

import rescue0 from '../../../assets/fashion/lookbook_rescue/rescue_000.png'
import rescue1 from '../../../assets/fashion/lookbook_rescue/rescue_001.png'
import rescue2 from '../../../assets/fashion/lookbook_rescue/rescue_002.png'

function FashionPost5() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={rescue0} alt="" />
            <div className='content-video' >
                <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865332764?h=88ba9aafbd?autoplay=1&loop=1&title=0&background=1`}
                    allowFullScreen
                />
            </div>
            <img className='content-img' src={rescue1} alt="" />
            <img className='content-img' src={rescue2} alt="" />
        </div>
    )
}


export default FashionPost5