import React from 'react'

import '../ServePost.css'

import ChevronRightArrow from '../../../assets/ChevronArrow.svg'
import street0 from '../../../assets/visual/editorial_street/street_000.png'

function VisualPost3() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={street0} alt="" />
        </div>
    )
}

export default VisualPost3